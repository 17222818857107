import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import _t from "@core/i18n"
import PackageBoxSlimMol from "@onestore/hel/dist/components/molecules/PackageBoxSlimMol"
import { PriceVariantType } from "@onestore/hel/dist/components/molecules/PriceMol/priceMol.types"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import { getCtaButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { getStaticPrices } from "@gatsby-plugin-generic-page/helpers/staticPrices"
import ProductBoxPriceOldVersion from "~/components/ProductBoxPriceOldVersion"
import type { StaticPrice } from "~/fragments/staticPrice"
import useAnchorProperties from "~/hooks/useAnchorProperties"
import usePriceConfigurationQuery from "~/hooks/usePriceConfigurationQuery"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import DetailedStaticPrice from "../DetailedStaticPrice"
import ProductBoxDiscountLabel from "./ProductBoxDiscountLabel"
import ProductBoxSlimFooter from "./ProductBoxFooter"

export type ProductBoxWithCtaAnchorProps = {
  product
  anchorId
  cta: CtaElement[]
  staticPrices?: StaticPrice[] | null
}

export default function ProductBoxWithCtaAnchor({
  product,
  anchorId,
  cta,
  staticPrices,
}: ProductBoxWithCtaAnchorProps): ReactElement<ProductBoxWithCtaAnchorProps> {
  const { showDetailedPrices } = usePriceConfigurationQuery()

  const prices = staticPrices
    ? getStaticPrices(staticPrices, product.priceType)
    : {}

  const { text, title, variant, gaEvent } = getCtaButtonFlatData(cta[0])

  const { href, onClick } = useAnchorProperties(anchorId)

  const period =
    Object.keys(prices).length === 1
      ? Object.keys(prices)[0]
      : product.period || Object.keys(prices)[0]

  const handleOnClick = (event: SyntheticEvent) => {
    sendGaEvent(gaEvent)

    onClick(event)
  }

  const renewalPayment =
    !isEmpty(prices) && prices[period].renewPrice
      ? _t("packageBoxSlim.renewalPrice", {
          price: prices[period].renewPrice?.value,
          suffix: prices[period].renewPrice?.suffix,
        })
      : product.additionalText || undefined

  let price: ReactElement | null = !isEmpty(prices) ? (
    <ProductBoxPriceOldVersion {...prices[period].price} />
  ) : null

  if (showDetailedPrices) {
    price = !isEmpty(staticPrices) ? (
      <DetailedStaticPrice
        variant={{
          type: PriceVariantType.LARGE,
          align: "default",
        }}
        staticPrices={staticPrices}
        period={period}
        customRenewAdditionalInfo={product.additionalText}
      />
    ) : null
  }

  return (
    <PackageBoxSlimMol
      {...product}
      button={{
        text,
        title,
        href: href,
        onClick: handleOnClick,
        color: product.buttonColor,
        variant,
      }}
      price={price}
      renewalPayment={showDetailedPrices ? undefined : renewalPayment}
      label={
        !isEmpty(product.discountLabel) ? (
          <ProductBoxDiscountLabel
            label={product.discountLabel}
            variant={product.discountLabelVariant}
          />
        ) : null
      }
      parameters={product.content.parameters}
      footer={
        product.content?.footer ? (
          <ProductBoxSlimFooter {...product.content.footer} />
        ) : undefined
      }
    />
  )
}
