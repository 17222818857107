import type { Context, ReactElement, ReactNode } from "react"
import { useContext } from "react"
import React from "react"
import { createContext, useState } from "react"
import { hasNoTaxRate } from "@core/pricing"
import { PriceType } from "@core/types"
import { useLocation } from "@reach/router"
import { useIsomorphicLayoutEffect } from "@gatsby-plugin-generic-page/hooks/useIsomorphicLayoutEffect"
import usePriceConfigurationQuery from "~/hooks/usePriceConfigurationQuery"
import isEmpty from "~/lib/isEmpty"
import Storage from "~/lib/storage"

type PriceTypeProps = {
  currentPriceType: PriceType
  isNettoPriceType: boolean
  setPriceType: { (priceType: PriceType): void }
}

const priceTypeContextDefault: PriceTypeProps = {
  currentPriceType: hasNoTaxRate() ? PriceType.NETTO : PriceType.GROSS,
  isNettoPriceType: hasNoTaxRate(),
  setPriceType: () => null,
}

export const PriceTypeContext = createContext<PriceTypeProps>(
  priceTypeContextDefault
)

export const usePriceTypeContext = () => useContext(PriceTypeContext)

export const isPriceType = (value: string): value is PriceType => {
  return Object.values(PriceType).includes(value as PriceType)
}

type PriceTypeContextProviderProps = {
  children: ReactNode
  isMarketplace?: boolean
  isPriceListPage?: boolean
}

export function PriceTypeContextProvider({
  children,
  isMarketplace,
  isPriceListPage,
}: PriceTypeContextProviderProps): ReactElement<Context<PriceTypeProps>> {
  const {
    defaultPriceType,
    defaultMarketplacePriceType,
    defaultPriceListPagePriceType,
  } = usePriceConfigurationQuery()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const priceParam = queryParams.get("priceType")

  const [genericCurrentPriceType, setGenericCurrentPriceType] =
    useState<PriceType>(defaultPriceType)

  const [marketplaceCurrentPriceType, setMarketplaceCurrentPriceType] =
    useState<PriceType>(defaultMarketplacePriceType)

  const [priceListCurrentPriceType, setPriceListCurrentPriceType] =
    useState<PriceType>(defaultPriceListPagePriceType)

  const getCurrentPriceType = (): PriceType => {
    if (isMarketplace) {
      return marketplaceCurrentPriceType
    }

    if (isPriceListPage) {
      return priceListCurrentPriceType
    }

    return genericCurrentPriceType
  }

  const setPriceType = (priceType: PriceType): void => {
    Storage.setLastPriceType(priceType)

    if (isMarketplace) {
      setMarketplaceCurrentPriceType(priceType)

      return
    }

    if (isPriceListPage) {
      setPriceListCurrentPriceType(priceType)

      return
    }

    setGenericCurrentPriceType(priceType)
    Storage.setGenericPriceType(priceType)
  }

  useIsomorphicLayoutEffect()(() => {
    //Override priceType by url param
    if (priceParam && isPriceType(priceParam)) {
      setPriceType(priceParam)

      Storage.setLastPriceType(priceParam)

      return
    }

    //Reset marketplace priceType when we are on other page type
    if (!isMarketplace) {
      setMarketplaceCurrentPriceType(defaultPriceListPagePriceType)
    }

    //Reset price list priceType when we are on other page type
    if (!isPriceListPage) {
      setPriceListCurrentPriceType(defaultPriceListPagePriceType)
    }

    //Get priceType from local storage on generic pages
    if (!isMarketplace && !isPriceListPage) {
      const storageGenericPriceType = Storage.getGenericPriceType()

      if (!isEmpty(storageGenericPriceType)) {
        setGenericCurrentPriceType(storageGenericPriceType)
      }
    }

    Storage.setLastPriceType(getCurrentPriceType())
  }, [isMarketplace, isPriceListPage])

  useIsomorphicLayoutEffect()(() => {
    if (hasNoTaxRate()) {
      setPriceType(PriceType.NETTO)
    }
  }, [
    genericCurrentPriceType,
    marketplaceCurrentPriceType,
    priceListCurrentPriceType,
  ])

  return (
    <PriceTypeContext.Provider
      value={{
        currentPriceType: getCurrentPriceType(),
        isNettoPriceType:
          getCurrentPriceType() === PriceType.NETTO || hasNoTaxRate(),
        setPriceType,
      }}
    >
      {children}
    </PriceTypeContext.Provider>
  )
}

export default PriceTypeContext
