import { first, get } from "lodash"
import { createSelector } from "reselect"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { ProductAlias } from "@onestore/api/types"
import {
  areRequestsPending,
  isResponsePending,
} from "@onestore/onestore-store-common/http"
import {
  getBasketItems,
  getBasketToken,
  getFlatBasketItems,
} from "@gatsby-plugin-basket/store/selectors"
import type {
  BonusDefinitions,
  BonusProduct,
  BonusState,
} from "@gatsby-plugin-bonus/types"
import { DOMAIN_STATUS } from "@gatsby-plugin-domain-search/store/constants"
import type { DomainSearchBundleState } from "@gatsby-plugin-domain-search/store/selectors"
import { getHookPlans, hasHostingHookEnabled } from "~/lib/config"
import type { AppState } from "~/store/reducer"
import { getUserDataReady } from "~/store/selectors"
import type { FormErrors } from "../../../../lib/api/forms"
import getBonusDefinitions from "../lib/bonus-definitions"
import getQuery from "../lib/query-provider"

export const initialState: BonusState = {
  loaded: false,
  definitionsLoaded: false,
  product: undefined,
  upgrade: undefined,
  bundled: undefined,
  calculation: {
    product: undefined,
    bundled: undefined,
  },
  domain: undefined,
  special: [],
  forms: {},
  upsell: {},
  upsellDomains: {
    results: [],
    status: 0,
  },
  promoCode: undefined,
  chosenPeriodName: undefined,
  hidePeriods: undefined,
  checkoutMode: "basket",
  domainsSearch: {},
  ...getBonusDefinitions(getQuery()),
}

/**
 * Zwraca definicje bonusów.
 */
export const getDefinitions = createSelector(
  (state: AppState): BonusState => state.bonus,
  (bonus: BonusState): BonusState => bonus
)

/**
 * Zwraca głowny produkt przed-koszyka.
 */
export const getProduct = createSelector(
  (state: AppState): BonusDefinitions => state.bonus,
  (bonus: BonusDefinitions): BonusProduct | undefined => bonus.product
)

export const hasProduct = createSelector(
  (state: AppState): BonusState => state.bonus,
  (bonus: BonusState): boolean => !!bonus.product
)

export const getProductAlias = createSelector(
  getProduct,
  (product) => product?.alias
)

/**
 * Zwraca głowny produkt przed-koszyka.
 *
 * @param {Object} state Stan aplikacji
 */
export const getUpsellProductFromBasket = createSelector(
  getBasketItems,
  (state) => getProduct(state)?.basketItemState?.id,
  (items, upsellProductId) =>
    first(items.filter((item) => item.id === upsellProductId))
)

/**
 * Zwraca zbundlowany produkt przed-koszyka.
 */
export const getBundled = createSelector(
  (state: AppState) => state.bonus,
  (bonus): BonusProduct | undefined => bonus.bundled
)

export const getPriceCalculations = createSelector(
  (state: AppState) => state.bonus,
  (bonus): BonusState["calculation"] => bonus.calculation
)

/**
 * Zwraca upsell produktu o danym aliasie.
 */
export const getUpsellProduct = createSelector(
  (state: AppState, alias: string): BonusProduct =>
    get(state, ["bonus", "upsell", alias]),
  (upsell: BonusProduct): BonusProduct => upsell
)

export const getDomain = createSelector(
  (state) => get(state, ["bonus", "domain"]),
  (results) => results
)

export const getDomains = createSelector(
  (state) => get(state, ["bonus", "domainsSearch"]),
  (results) => results
)

export const getDomainsNextStep = createSelector(
  (state: AppState): boolean =>
    get(state, ["bonus", "domainsSearch", "nextStep"]),
  (results: boolean): boolean => results
)

export const getDomainsHideSearch = createSelector(
  (state: AppState): boolean =>
    get(state, ["bonus", "domainsSearch", "hideSearch"]),
  (results: boolean): boolean => results
)

export const getUpsellDomains = createSelector(
  (state) => get(state, ["bonus", "upsellDomains"]),
  (results) => results
)

export const getUpsellDomainsResultsList = (state) =>
  get(state, ["bonus", "upsellDomains", "results"], [])

export const getDomainsResultsList = (state): DomainCheck.Result[] => {
  const results = get(state, ["bonus", "domainsSearch", "results"], [])

  return [...results]
}

/**
 * Zwraca informacje o bundle domenowym
 */
export const getDomainsBundles = (
  state: AppState & AppState
): DomainSearchBundleState[] => {
  const bundleDomains = get(
    state,
    ["bonus", "domainsSearch", "bundleDomains"],
    null
  )

  if (!bundleDomains) {
    return []
  }

  return bundleDomains.map((bundle) => ({
    ...bundle,
    buttonId: `domains-bundle-${bundle.id}`,
    isLoading: isResponsePending(
      state.basket.buttons[`domains-bundle-${bundle.id}`]
    ),
    isPartialBundleInBasket: bundle.domains.some((domain) =>
      state.basket.domains.includes(domain.fqdn)
    ),
    isWholeBundleInBasket: bundle.domains.every((domain) =>
      state.basket.domains.includes(domain.fqdn)
    ),
  }))
}

/**
 * Zwraca informacje o wyszukiwanych domenach w upsellu domenowym.
 * Dodatkowo sortuje wyniki wyszukiwania tak by domeny dostępne pojawiały się wyżej.
 */
export const getUpsellDomainResults = createSelector(
  getUpsellDomainsResultsList,
  (results) =>
    results.slice(0).sort((a, b) => {
      if (a.status === b.status) {
        return 0
      }

      if (a.status === DOMAIN_STATUS.AVAILABLE) {
        return -1
      }

      if (b.status === DOMAIN_STATUS.AVAILABLE) {
        return 1
      }

      return 0
    })
)

export const getDomainResults = createSelector(
  getDomainsResultsList,
  (results) =>
    results.sort((a, b) => {
      if (a.status === b.status) {
        return 0
      }

      if (a.status === DOMAIN_STATUS.AVAILABLE) {
        return -1
      }

      if (b.status === DOMAIN_STATUS.AVAILABLE) {
        return 1
      }

      return 0
    })
)
/**
 * Zwraca informacje o wprowadzonej frazie w upsellu domenowym.
 */
export const getUpsellDomainPhrase = createSelector(
  (state) => getUpsellDomains(state),
  (results) => results.phrase
)

export const getDomainPhrase = createSelector(
  (state) => getDomains(state),
  (results) => results.phrase
)

/**
 * Zwraca informacje o statusie zapytania HTTP.
 */
export const getUpsellDomainsRequestStatus = createSelector(
  (state) => getUpsellDomains(state),
  (results) => results.status
)

export const getDomainsRequestStatus = createSelector(
  (state) => getDomains(state),
  (results) => results.status
)

export const getBonusPromoCode = createSelector(
  (state: AppState): BonusState => state.bonus,
  (bonus: BonusState): string => bonus.promoCode || ""
)

export const getHideBonusPeriods = createSelector(
  (state: AppState): BonusState => state.bonus,
  (bonus: BonusState): boolean => bonus.hidePeriods || false
)

export type ParametersFormData = Record<
  string,
  string | number | boolean | null
>

export function getBonusParametersFormValues(alias: ProductAlias): {
  (state: AppState): Record<string, string>
} {
  return (state: AppState) => get(state, `bonus.forms.${alias}.values`, {})
}

export function isBonusParameterFormValid(alias: ProductAlias): {
  (state: AppState): boolean
} {
  return (state: AppState) => get(state, `bonus.forms.${alias}.valid`, true)
}

export function getBonusParametersFormExternalErrors(errorPath: string) {
  return (state: AppState): FormErrors | undefined =>
    get(state, `bonus.${errorPath}.addToBasketError`, undefined)
}

export function getAdvancedProductsAliases({ bonus }: AppState): string[] {
  const aliases: string[] = []

  if (bonus?.product?.alias && bonus?.product?.advancedConfiguration) {
    aliases.push(bonus?.product?.alias)
  }

  Object.values(bonus.upsell).forEach((upsell) => {
    if (upsell?.advancedConfiguration) {
      aliases.push(upsell?.alias)
    }
  })

  return aliases
}

export function allAdvancedDefinitionsLoaded({ bonus }: AppState): boolean {
  const aliases: boolean[] = []

  if (bonus?.product?.alias && bonus?.product?.advancedConfiguration) {
    aliases.push(bonus?.product?.definitionLoaded)
  }

  Object.values(bonus.upsell).forEach((upsell) => {
    if (upsell?.advancedConfiguration) {
      aliases.push(upsell?.definitionLoaded)
    }
  })

  if (aliases.length === 0) {
    return true
  }

  return aliases.every((value) => value)
}

export interface BonusContextData {
  ready: boolean
  definitions: BonusDefinitions
  product: BonusProduct | undefined
}
export function getBonusContextData(state: AppState): BonusContextData {
  const userDataReady = getUserDataReady(state)
  const product = getProduct(state)
  const token = getBasketToken(state)
  const definitionsLoaded = allAdvancedDefinitionsLoaded(state)
  const definitions = getDefinitions(state)

  return {
    ready: userDataReady === true && token !== null && definitionsLoaded,
    definitions,
    product,
  }
}

export function getBonusHookVisible(state: AppState): boolean {
  const definitions = getDefinitions(state)

  if (!hasHostingHookEnabled()) {
    return false
  }

  return (
    Object.hasOwnProperty.call(definitions, "special") &&
    definitions.special !== undefined &&
    definitions.special.indexOf("DOMAINS") > -1
  )
}

export function hasHookItemsInBasket(state: AppState) {
  const basketItems = getFlatBasketItems(state)

  return basketItems.some((item) => getHookPlans().includes(item.plan_id))
}

export function isBundleLoading(state: AppState): boolean {
  const bundle = getBundled(state)

  if (!bundle) {
    return true
  }

  return areRequestsPending([
    bundle.addToBasketStatus,
    bundle.changePeriodStatus,
    bundle.removeFromBasketStatus,
  ])
}
