import type { ReactElement } from "react"
import React, { useState } from "react"
import _t from "@core/i18n"
import PackageBoxSlimMol from "@onestore/hel/dist/components/molecules/PackageBoxSlimMol"
import { PriceVariantType } from "@onestore/hel/dist/components/molecules/PriceMol/priceMol.types"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import { getCtaButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { getStaticPrices } from "@gatsby-plugin-generic-page/helpers/staticPrices"
import FormModal from "~/components/FormModal"
import ProductBoxPriceOldVersion from "~/components/ProductBoxPriceOldVersion"
import type { Form } from "~/fragments/form"
import type { StaticPrice } from "~/fragments/staticPrice"
import { useFormHook } from "~/hooks/useFormHook"
import usePriceConfigurationQuery from "~/hooks/usePriceConfigurationQuery"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import DetailedStaticPrice from "../DetailedStaticPrice"
import ProductBoxDiscountLabel from "./ProductBoxDiscountLabel"
import ProductBoxSlimFooter from "./ProductBoxFooter"

export type ProductBoxWithCtaFormProps = {
  product
  cta: CtaElement[]
  form: Form[]
  staticPrices?: StaticPrice[]
}

export default function ProductBoxWithCtaForm({
  product,
  cta,
  form,
  staticPrices,
}: ProductBoxWithCtaFormProps): ReactElement<ProductBoxWithCtaFormProps> {
  const { showDetailedPrices } = usePriceConfigurationQuery()

  const prices = staticPrices
    ? getStaticPrices(staticPrices, product.priceType)
    : {}

  const { text, title, variant, gaEvent } = getCtaButtonFlatData(cta[0])

  const [isVisible, setIsVisible] = useState(false)

  const formData = useFormHook(form[0].flatData.id)

  const handleFormButton = (event) => {
    event.preventDefault()
    event.stopPropagation()

    sendGaEvent(gaEvent)

    if (formData) {
      formData.fetchData()
      setIsVisible(true)
    }
  }

  const period =
    Object.keys(prices).length === 1
      ? Object.keys(prices)[0]
      : product.period || Object.keys(prices)[0]

  const renewalPayment =
    !isEmpty(prices) && prices[period].renewPrice
      ? _t("packageBoxSlim.renewalPrice", {
          price: prices[period].renewPrice?.value,
          suffix: prices[period].renewPrice?.suffix,
        })
      : product.additionalText || undefined

  let price: ReactElement | null = !isEmpty(prices) ? (
    <ProductBoxPriceOldVersion {...prices[period].price} />
  ) : null

  if (showDetailedPrices) {
    price = !isEmpty(staticPrices) ? (
      <DetailedStaticPrice
        variant={{
          type: PriceVariantType.LARGE,
          align: "default",
        }}
        staticPrices={staticPrices}
        period={period}
        customRenewAdditionalInfo={product.additionalText}
      />
    ) : null
  }

  return (
    <>
      <PackageBoxSlimMol
        {...product}
        button={{
          text,
          title,
          href: "#",
          onClick: handleFormButton,
          color: product.buttonColor,
          variant,
        }}
        price={price}
        renewalPayment={showDetailedPrices ? undefined : renewalPayment}
        label={
          !isEmpty(product.discountLabel) ? (
            <ProductBoxDiscountLabel
              label={product.discountLabel}
              variant={product.discountLabelVariant}
            />
          ) : null
        }
        parameters={product.content.parameters}
        footer={
          product.content?.footer ? (
            <ProductBoxSlimFooter {...product.content.footer} />
          ) : undefined
        }
      />

      {isVisible ? (
        <FormModal
          headingText={form[0].flatData.title}
          id={form[0].flatData.id}
          button={form[0].flatData.button[0]}
          onCloseButtonClick={() => {
            setIsVisible(false)
          }}
        />
      ) : null}
    </>
  )
}
