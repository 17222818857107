import log from "~/lib/log"

declare global {
  // OAO can be undefined on some brands when GATSBY_OAO_TENANT is not set
  interface Window {
    OAO?: {
      q: {
        c: any[]
        t: any[]
      }
    }
  }
}

export function initOaO(): void {
  if (!window.OAO) {
    log("missing", "oao")

    return
  }
  log("starting", "oao")

  window.OAO.q.t.push(["setUserConsent", true])
  log(["setUserConsent", true], "oao")
}

export function pushPageView(): void {
  if (!window.OAO) {
    return
  }
  window.OAO.q.t.push(["pageView"])
  log("pageView", "oao")
}
