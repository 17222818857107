import type { Draft } from "immer"
import { produce } from "immer"
import { CoreActionTypes } from "~/store/core/actions"
import { MIN_API_VERSION } from "../../index"

export type CoreState = {
  readonly minApiVersion: string
  readonly currentApiVersion: string | null
}

export const initialState: CoreState = {
  minApiVersion: MIN_API_VERSION,
  currentApiVersion: null,
}

export default function reducer(
  state: CoreState = initialState,
  action: any
): CoreState {
  return produce<CoreState, CoreState>(state, (nextState: Draft<CoreState>) => {
    switch (action.type) {
      case CoreActionTypes.UPDATE_API_VERSION:
        nextState.currentApiVersion = String(action.version).replace("v", "")
        // eslint-disable-next-line
        return
    }
  })
}
