import type { PriceType } from "@core/types"
import Cookies from "js-cookie"
import LocalStorage from "./LocalStorage"
import SessionStorage from "./SessionStorage"

type AccountType = "person" | "company"
type BasketToken = string | null

export type SearchUrlParameterType =
  | "utm_source"
  | "utm_medium"
  | "utm_campaign"

export type SearchUrlParameters = {
  [key in SearchUrlParameterType]: string | null
}

export const STORAGE = {
  LOGIN_EXISTS: "_exists",
  OLD_ORDER: "_old_order",
  CONTROL_PANEL: "_cpUrl",
  DOMAINS_COUNT: "_domainsInBasket",
  RESULTS_VIEW_TYPE: "_results_view_type",
  SESSION_ID: "_session-id",
  TOKEN_NAME: "_token",
  USER_LOGIN: "_login",
  SIG: "_onestore-sig",
  BASKET_INFO: "_basketInfo",
  BASKET_INFO_CNT: "itemsCount",
  BASKET_INFO_NET_PRICE: "totalNetPrice",
  BASKET_INFO_VAT_VALUE: "totalVatValue",
  BASKET_INFO_FULL: "_fullBasketInfo",
  BASKET_PRODUCTS: "_products",
  ACCOUNT_TYPE: "_account-type",
  OPINIONS: "_opinions",
  COOKIE_INFO: "cookieInfo",
  LAST_PRICE_TYPE: "_last_price_type",
  PRICE_TYPE_GENERIC: "_price_type_generic",
  NOTIFICATION_BARS: "notificationBars",
  HOSTING_PROMO_MODAL: "hostingPromoModal",
  DOMAIN_HOOK_ADDED_TO_BASKET: "hookAddedToBasket",
  SEARCH_URL_PARAMETERS: "searchUrlParameters",
}

export default class Storage {
  static getSig(): string | null {
    return LocalStorage.get(STORAGE.SIG)
  }

  static setSig(sig: string): void {
    LocalStorage.set(STORAGE.SIG, sig)
  }

  static clearSig(): void {
    LocalStorage.remove(STORAGE.SIG)
  }

  static getToken(): BasketToken {
    return LocalStorage.get(STORAGE.TOKEN_NAME)
  }

  static setToken(token: BasketToken): void {
    LocalStorage.set(STORAGE.TOKEN_NAME, token as string)
  }

  static clearToken(): void {
    LocalStorage.remove(STORAGE.TOKEN_NAME)
  }

  static setBasketProducts(values: string[]): void {
    SessionStorage.setArray(STORAGE.BASKET_PRODUCTS, values)
  }

  static getBasketProducts(): string[] {
    return SessionStorage.getArray(STORAGE.BASKET_PRODUCTS)
  }

  static setDomainsCount(count: number): void {
    SessionStorage.set(STORAGE.DOMAINS_COUNT, `${count}`)
  }

  static getDomainsCount(defaultNumber = 0): number {
    return parseInt(
      SessionStorage.get(STORAGE.DOMAINS_COUNT) || `${defaultNumber}`,
      10
    )
  }

  static setSession(sessionId: string, expires: Date): void {
    Cookies.set(STORAGE.SESSION_ID, sessionId, {
      expires,
    })
  }

  static clearSessionId(): void {
    Cookies.remove(STORAGE.SESSION_ID)
  }

  static getSessionId(): string | null {
    return Cookies.get(STORAGE.SESSION_ID) || null
  }

  static setAccountType(type: AccountType): void {
    LocalStorage.set(STORAGE.ACCOUNT_TYPE, type as string)
  }

  static clearAccountType(): void {
    LocalStorage.remove(STORAGE.ACCOUNT_TYPE)
  }

  static getAccountType(): AccountType | null {
    return (LocalStorage.get(STORAGE.ACCOUNT_TYPE) as AccountType) || null
  }

  static getLastPriceType(): PriceType | null {
    return (SessionStorage.get(STORAGE.LAST_PRICE_TYPE) as PriceType) || null
  }

  static setLastPriceType(priceType: PriceType): void {
    SessionStorage.set(STORAGE.LAST_PRICE_TYPE, priceType)
  }

  static getGenericPriceType(): PriceType | null {
    return (LocalStorage.get(STORAGE.PRICE_TYPE_GENERIC) as PriceType) || null
  }

  static setGenericPriceType(priceType: PriceType): void {
    LocalStorage.set(STORAGE.PRICE_TYPE_GENERIC, priceType)
  }

  static getSearchUrlParameters(): SearchUrlParameters | {} {
    return LocalStorage.getSerialized<SearchUrlParameters>(
      STORAGE.SEARCH_URL_PARAMETERS
    )
  }

  static setSearchUrlParameters(parameters: SearchUrlParameters): void {
    LocalStorage.setSerialized(STORAGE.SEARCH_URL_PARAMETERS, parameters)
  }
}
